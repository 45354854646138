
import {

  IonContent,
  IonPage,
  IonList,
  IonListHeader,
  onIonViewWillEnter,
  modalController,
  IonBadge,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/vue';
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { caretForward, bookmark } from 'ionicons/icons';
import { useHome } from '@/api/services/home';
import Modal1 from '@/views/modal/UserModal3.vue'
// import { alert } from '@/utils/alert';
// import { toast } from '@/utils/toast';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonPage,
    IonList,
    IonListHeader,
    IonBadge,
    IonGrid,
    IonRow,
    IonCol
  },
  setup() {
    const { newCnt, fetchData,chkAppr } = useHome();
    const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);

    const openModalPassword = async () => {
      const modal = await modalController.create({
        component: Modal1,
        cssClass: 'my-modal-class3',
        componentProps: {
          title: 'pwd'
        },
        backdropDismiss: false
        //Modal is name of the component to render inside ionic modal
      });
      return modal.present();
    };
    async function chkErp(chk: any){
      await chkAppr(chk);
    }
    onIonViewWillEnter(async () => {
      await fetchData();
      if (newCnt.value.credentialsExpired < 0) {
        await openModalPassword();
      }
    });

    return {
      caretForward,
      bookmark,
      auth,
      newCnt,
      openModalPassword,
      chkErp
    };
  }
});
