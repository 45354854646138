
import { users } from '@/api/services/user';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonButton, IonFooter } from "@ionic/vue";
import { defineComponent,  reactive } from "vue";
export default defineComponent({

  name: "Modal",
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonButton, IonFooter },
  props: {
    title: {
      type: String
    },
    name: {
      type: String
    },
    email: {
      type: String
    }
  },

  setup(props) {
    const { updateUserInfo,updateRrdate } = users();


    const form = reactive({
      title: props.title,
      pwd: {
        password: '',
        newPassword: '',
        passwordConfirm: ''
      }

    });
    function submit() {
      updateUserInfo(form.title, form.pwd)
    }
    function submitExtension() {
      updateRrdate()
    }

    return { form, updateUserInfo, submit, submitExtension, updateRrdate }
  }
});

