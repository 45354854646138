<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>비밀번호 만료일경과</ion-title>
    </ion-toolbar>
  </ion-header>
    <ion-content>
      <ion-item>
        <ion-label position="floating">현재 비밀번호</ion-label>
        <ion-input type="password" v-model='form.pwd.password' maxlength="20" placeholder="이전 비밀번호 입력"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">새 비밀번호</ion-label>
        <ion-input type="password" v-model='form.pwd.newPassword' maxlength="20" placeholder="새로운 비밀번호 입력"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">새 비밀번호 확인</ion-label>
        <ion-input type="password" v-model='form.pwd.passwordConfirm' maxlength="20"
          placeholder="새로운 비밀번호 확인"></ion-input>
      </ion-item>
    </ion-content>
  <ion-footer class="ion-no-border">
    <div class='center-button'>
      <ion-button class="record-btn" color="medium" fill ="outline" @click="submitExtension">만료일 90일 연장</ion-button>
      <ion-button class="record-btn" color="medium" @click="submit">비밀번호 변경</ion-button>
    </div>
  </ion-footer>
</template>

<script lang="ts">
import { users } from '@/api/services/user';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonButton, IonFooter } from "@ionic/vue";
import { defineComponent,  reactive } from "vue";
export default defineComponent({

  name: "Modal",
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonButton, IonFooter },
  props: {
    title: {
      type: String
    },
    name: {
      type: String
    },
    email: {
      type: String
    }
  },

  setup(props) {
    const { updateUserInfo,updateRrdate } = users();


    const form = reactive({
      title: props.title,
      pwd: {
        password: '',
        newPassword: '',
        passwordConfirm: ''
      }

    });
    function submit() {
      updateUserInfo(form.title, form.pwd)
    }
    function submitExtension() {
      updateRrdate()
    }

    return { form, updateUserInfo, submit, submitExtension, updateRrdate }
  }
});

</script>

<style>
.center-button {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  height: 20%;
}

.record-btn {
  height: 40px;
  width: 120px;
}
</style>